import React from "react";
import { Line } from "react-chartjs-2";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend,
} from "chart.js";

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Filler,
  Legend
);

export default function AvailableFuelChart(props) {
  const options = {
    maintainAspectRatio: false,
    responsive: true,
    backgroundColor: "transparent",
    plugins: {
      legend: {
        position: "top",
        display: true,
      },
    },
    elements: {
      point: {
        radius: 0,
      },
    },
    scales: {
      x: {
        grid: {
          display: false,
        },
        ticks: {
          font: {
            size: 10,
            // family: "vazir",
          },
        },
      },
      y: {
        grid: {
          display: true,
        },
        ticks: {
          font: {
            size: 12,
            // family: "vazir",
          },
        },
      },
      y: {
        // beginAtZero: true,
        // max: props.maxValueArea,
      },
    },
  };
  const labels = props.labels;

  const label1 = props.labelname1;

  const data = {
    labels,
    datasets: [
      {
        fill: false,
        label: label1,
        data: props?.availableFuel,
        // borderColor: "rgb(255, 87, 51)",
        borderColor: "#9b7a01",
        // backgroundColor: 'rgba(169, 204, 227, 0.7)',
        radius: 3,
        borderJoinStyle: "round",
      },
    ],
  };
  return (
    <>
      <Line options={options} data={data} height={285} width={350} />
    </>
  );
}

// Inside AvailableFuelChart component where you configure the chart options
const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      display: true,
    },
  },
  elements: {
    point: {
      radius: 0,
    },
  },
  scales: {
    x: {
      grid: {
        display: false,
      },
    },
    y: {
      grid: {
        display: true,
      },
    },
  },
  // Add this line to make the chart background transparent
  backgroundColor: "transparent",
};
